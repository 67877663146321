import { Messages } from 'primereact/messages';
import React, { useEffect } from 'react';

const showReleaseMessage = true;

const ReleaseMessage: React.FC = () => {
  const isNaRegion = window.location.hostname.endsWith('.ca');
  const scheduledTime = isNaRegion ? 'Tuesday, 28th October at 7:00 PM CT' : 'Tuesday, 29th October at 6:00 AM AWST';

  const messages = React.useRef<any>();

  useEffect(() => {
    if (showReleaseMessage) {
      messages.current?.replace({
        severity: 'info',
        sticky: true,
        content: (
          <>
            <div className="p-message-detail">
              <b className="p-message-summary">Product Release 3.0.3</b>
              <p className="p-message-summary">
                We are excited to announce INX Assessor product release version 3.0.3. The product release is scheduled
                for <b>Tuesday, 15th October</b>. There may be a short period of downtime including intermitted
                interruptions during this period.
              </p>
              <p className="p-message-summary">
                The current version of the Assessor mobile app (v3.0.2) remains backward compatible and will continue to
                function, but we recommend upgrading to the latest version (v3.0.3) on or after 15th October. The app is
                available for download from both the Android and iOS app stores. Please sync all mobile assessments
                before upgrading to ensure a smooth transition.
              </p>
              <p className="p-message-summary">
                The{' '}
                <a
                  href="https://www.inxsoftware.com/release-note/introducing-the-new-inx-assessor-brand-identity-improved-search-functionality-in-landscape-view-in-the-mobile-application/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  INX Assessor release notes
                </a>{' '}
                contain information on the changes for release.
              </p>
              <b className="p-message-summary">Scheduled Maintenance</b>
              <p className="p-message-summary">
                Maintenance is scheduled for <b>{scheduledTime}</b> on the INX Assessor production environment. There
                will be up to 2 hours of downtime expected during this period.
              </p>
            </div>
          </>
        ),
      });
    }
  }, [messages, scheduledTime]);

  return <Messages ref={messages} />;
};

export default ReleaseMessage;
